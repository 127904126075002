@mixin text-font($size, $color, $weight, $lineHeight) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lineHeight;
  color: $color;
}

$ha-yellow: #ffda02;
$ha-red: #fb462a;
$ha-teal: #75ffa4;
$ha-blue: #2e20b1;
$ha-black: #000000;
$danger: #fb462a;
$primary: #2e20b1;
$ha-blue-text: #0066ff;
$ha-bg-color: #f8f6ff;
$ha-btn-hover-color: #0056b3;
$ha-bg-grey: #9f9f9f;
$menu-width: 240px;

.title-large {
  @include text-font(20px, #000000, 600, 24px);
}

.title-semibold {
  @include text-font(16px, #000000, 600, 20px);
}

.title-medium {
  @include text-font(14px, #000000, 500, 17px);
}

.title-14-20-medium {
  @include text-font(14px, #000000, 500, 20px);
}

.title-bold {
  @include text-font(14px, #000000, 600, 17px);
}

.title-10-bold {
  @include text-font(10px, #000000, 600, 12px);
}

.description-grey {
  @include text-font(14px, #6f6d6d, 500, 20px);
}

.ha-background {
  background-color: $ha-bg-color;
}

.blue-text {
  color: $ha-blue;
}

.text-btn-primary {
  color: $primary;

  &:hover {
    color: $ha-btn-hover-color;
  }
}

// overriding bootstrap default colors
.btn-danger {
  background-color: $danger;
  border-color: $danger;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
}

.btn-outline-primary {
  border-color: $primary;
  color: $primary;

  &:hover {
    background-color: $primary;
    border: $primary;
  }
}

strong {
  font-weight: 600;
}

.robo-font {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.centering-div-container {
  display: flex;
  justify-content: center;
}

.omni-error {
  position: fixed;
  top: 92px;
  left: 30vw;
  width: 50vw;
  z-index: 99999;
  border: 4px solid #dc3545;
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.2);
}

.manual-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.omni-success {
  position: fixed;
  top: 92px;
  left: 30vw;
  width: 50vw;
  z-index: 99999;
  border: 4px solid #28a745;
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.2);

  .toast-header {
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.toast {
  max-width: unset;
}

.modal-content {
  margin-top: 10%;
  padding: 5px;

  @media (max-width: 892px) {
    margin-left: unset;
  }
}

.app-wrapper {
  display: flex;
  background-color: #e9eef2;
}

.headline {
  font-family: montserrat;
  font-weight: bold;
  font-size: 34px;
  text-transform: uppercase;
  letter-spacing: -1px;
}

.subhead {
  // font-family: montserrat;
  // font-weight: bold;
  // font-size: 32px;
  // letter-spacing: 3px;
  // text-transform: uppercase;

  /* 14 - SemiBold */

  padding: 1.625rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.subhead-no-cap {
  font-family: montserrat;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 3px;
}

.text {
  font-family: montserrat;
  font-weight: light;
}

.inter {
  font-family: inter;
}

.max-btn-height {
  max-height: 38px;
}

.page-content {
  display: block;
  width: calc(100% - 50px);
  margin: 110px 25px 0 25px;
  height: auto;
  min-height: 100vh;
}

.feed-image {
  // max-width: 130px;
  // width: 200px;
  max-height: 110px;
  object-fit: cover;
}

.button-spacing {
  display: inline-block;
  padding: 0.375rem 0.75rem;
}

.draggable-cursor-area {
  cursor: grab;
}

.react-bootstrap-table .table {
  table-layout: auto;
}

.react-tags__search-input {
  min-width: 100px;
}

.react-tags__selected .react-tags__search-input {
  user-select: none;
}

// .stickyPadding {
//   padding-bottom: 6rem;
// }

.sticky {
  z-index: 1;
}

.sticky > div {
  margin-top: 6px;
  padding-top: 25px;
  padding-bottom: 20px;
  background-color: white;
}

.search-placeholder-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6f6d6d;
}

.header-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
}

.header-back-button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0066ff;

  &:hover {
    text-decoration: none;
    columns: #20b14c;
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-img {
  width: 40%;
}

.ql-editor {
  min-height: 8rem;
}

.custom-btn {
  border-color: $primary !important;
  color: $primary !important;

  &:hover {
    background-color: $primary !important;
    color: #ffffff !important;
  }

  &:disabled {
    background-color: #9f9f9f !important;
    color: #ffffff !important;
    border: 1px solid #9f9f9f !important;
  }
}

.custom-btn-reverse {
  background-color: $primary !important;
  color: #ffffff !important;

  &:hover {
    background-color: white !important;
    border-color: $primary !important;
    color: $primary !important;
  }

  &:disabled {
    background-color: #9f9f9f !important;
    color: #ffffff !important;
    border: none;
  }
}

.custom-btn-danger {
  border-color: $danger !important;
  color: $danger !important;

  &:hover {
    background-color: $danger !important;
    color: #ffffff !important;
  }

  &:disabled {
    background-color: #9f9f9f !important;
    color: #ffffff !important;
    border: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(235, 233, 233);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(247, 231, 231);
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "./src/components/pages/Homepage.scss";
@import "./src/components/pages/Profile.scss";
@import "./src/components/pages/Account.scss";
@import "./src/components/pages/Billing/BillingInfo.scss";
@import "./src/components/pages/CreateFeed/CreateFeed.scss";
@import "./src/components/pages/Feed.scss";
@import "./src/components/pages/MyFeeds.scss";
@import "./src/components/pages/Episode/Episode.scss";
@import "./src/components/pages/Integrations.scss";
@import "./src/components/pages/Actions.scss";
@import "./src/components/pages/Superlisteners.scss";
@import "./src/components/pages/Superlistener.scss";
@import "./src/components/pages/AdminTools.scss";
@import "./src/components/pages/Feed/Feed.scss";
@import "./src/components/pages/AudioInbox/AudioInbox.scss";
@import "./src/components/pages/SubscriptionPage/SubscriptionPage.scss";

@import "./src/components/widgets/CreditCardForm.scss";
@import "./components/ui/FeedImage/FeedImage.scss";
@import "./components/ui/CheckboxSecondary/CheckboxSecondary.scss";
