.linkImports .react-tags__selected-tag {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
}

.my-feed-image {
  border-radius: 4px;
}

.delete-btn-border {
  border-color: #fb462a !important;
  color: #fb462a !important;

  &:hover {
    background-color: #fb462a !important;
    color: #ffffff !important;
  }
}

.ellipsis-input {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-feed-title {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feed-tooltip {
  padding: 0.5rem;
  @include text-font(12px, #000000, 500, 26px);
}

.my-feed-link-btn {
  &:focus {
    box-shadow: none;
    outline: none;
    text-decoration: none;
  }
}

.my-feed-stats-img-wrap-episodes {
  padding: 10px;
  border-radius: 4px;
  background: #f7f6fc;
}

.my-feed-stats-img-wrap-listeners {
  padding: 10px;
  border-radius: 4px;
  background: #fffef5;
}

.my-feed-stats-border {
  border-color: #f8f6ff;
}

.my-feed-stats-img-wrap-feedDownloads {
  padding: 10px;
  border-radius: 4px;
  background: #fafffd;
}

.confirm-btn {
  border-radius: 4px;
  background: var(--main-color, #2e20b1);
  color: #ffffff;

  &:disabled {
    background: var(--main-color, #9f9f9f);
    border: none;
  }
}

.my-feed-dynamic-description-wrap {
  background-color: #f7f6fc;
  padding: 10px;
}

.my-feed-link-button {
  @include text-font(14px, #2e20b1, 600, 17px);
  color: #2e20b1;
  text-decoration: none;
  outline: none;
  background: none;
  border: none;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  &:focus {
    text-decoration: none;
    outline: none;
    border: none;
  }

  &:hover {
    color: #5e54b8;
    text-decoration: none;
    outline: none;
    border: none;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }
}

//tw-w-full tw-flex tw-justify-between
.feed-button-mobile {
  display: none;

  // Show on screens smaller than 640px (mobile screens)
  @media only screen and (max-width: 767px) {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .justify-mobile-center {
    display: flex;
    justify-content: center;
  }
}

.right-pan {
  padding-left: 0;

  @media (max-width: 767px) {
    padding-left: 1rem;
    margin-bottom: 2rem;
  }
}

.table-desktop {
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }
}

.table-mobile {
  display: none;

  @media (max-width: 767px) {
    // background: $ha-bg-grey;
    display: block;
  }
}

.episode-image-group {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  max-width: 100%;

  @media (max-width: 1422px) {
    justify-content: center;
  }
}

.table-justify {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-tr {
  // display: block;

  &:focus-visible {
    outline: none;
  }
}
