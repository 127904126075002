.superlisteners-first-name {
  min-width: 145px;
}

.superlisteners-last-name {
  min-width: 145px;
}

.superlisteners-tags {
  max-width: 343px;
}
