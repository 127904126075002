.my-feeds-background {
  display: block;
  margin: 25px auto;
  background-color: white;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.35);
  padding: 25px;
  border-radius: 3px;
  // max-width: 100%;

  .mf-searchbar {
    width: 400px;
    margin: auto;
  }

  img:hover {
    fill: white;
  }

  .feed-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    width: 50px;
    min-width: 50px;
    object-fit: cover;
  }

  .status-col {
    min-width: 126px;
  }

  .type-col {
    min-width: 116px;
  }

  .table td {
    border-top: none;
    vertical-align: middle;
  }

  .table thead th {
    border: none;
  }

  .feed-icon {
    margin: 0 10px;
    position: relative;
    top: -2px;
    border-radius: 5px;
    vertical-align: center;
    height: 20px;
    width: 20px;
  }
}

.feed-card {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.35);
}
