@mixin text-font($size, $color, $weight, $lineHeight) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lineHeight;
  color: $color;
}

.audio-inbox-title-top {
  text-align: center;
  margin-bottom: 25px;
  @include text-font(14px, #000000, 600, 17px);
}

.audio-inbox-title {
  margin: 15px;
}

.audio-inbox-text-normal {
  @include text-font(14px, #000000, 500, 17px);
}

.audio-inbox-text-normal-grey {
  @include text-font(14px, #9f9f9f, 500, 17px);
}

.audio-inbox-modal-text {
  @include text-font(14px, #6f6d6d, 500, 17px);
  transform: rotate(-0.01deg);
  text-align: center;
}

.audio-inbox-modal-title {
  @include text-font(16px, #000000, 600, 20px);
}

.audio-inbox-platforms {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
}

.audio-inbox-platforms-container {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0px 4px 4px rgba(244, 241, 255, 0.25), 0px 4px 20px -2px #f4f1ff;

  &:hover {
    border: 1px solid #2e20b1;
    text-decoration: none;
  }
}

.audio-inbox-platforms-warp {
  border-radius: 3px;
  max-width: 100%;
}

.audio-inbox-link-btn {
  color: #0066ff;
  text-decoration: none;
  outline: none;
  background: none;
  border: none;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;

  &:focus {
    text-decoration: none;
    outline: none;
    border: none;
  }

  &:hover {
    color: #5e54b8;
    text-decoration: none;
    outline: none;
    border: none;
  }
}

.audio-inbox-button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #ffffff;
  height: 38px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  box-shadow: none !important;

  &:disabled {
    background: grey;
    border: grey;
  }
}

.audio-inbox-btn-wrap {
  display: flex;
  align-items: flex-end;
  margin: 0 15px 15px 15px;
}

.table-container {
  margin: 1rem;
}

.table td,
.table th {
  border: none !important;
}

.custom-checkbox input[type="checkbox"] {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
}

.custom-checkbox input[type="checkbox"]:checked + label::before {
  content: "\2713";
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #00ff22;
}

.custom-checkbox label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.audio-inbox-appear-in {
  @include text-font(14px, #000000, 500, 17px);
}

.audio-inbox-appear-none {
  @include text-font(14px, #9f9f9f, 500, 17px);
}

.audio-inbox-tooltip-placeholder {
  background: rgba(46, 32, 177, 0.04);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 10px;
  @include text-font(10px, #0066ff, 600, 12px);
}

.audio-inbox-tooltip {
  background: #f6f5fb;
  padding: 1rem;
  @include text-font(12px, #000000, 500, 26px);
}

.ellipsis-appearsIn {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-appears-title {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;

  @media (max-width: 1240px) {
    max-width: 200px;
  }

  @media (min-width: 1490px) {
    max-width: 350px;
  }
}

.ellipsis-mobile-title {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.audio-inbox-disconnect {
  color: $danger;

  &:hover {
    opacity: 0.75;
  }
}

.tooltip-popup-warpper {
  --tooltipBackground: #f6f5fb;
  --tooltipBorder: #f6f5fb;
  --tooltipColor: #000;
  padding: 10px;
  border-radius: 2px;
  box-shadow: none;
}

.tooltip-text {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
