.admin-email-col {
  max-width: 200px;
}

.admin-name-col {
  max-width: 200px;
}

.admin-override-col {
  min-width: 290px;
}
