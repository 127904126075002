.feed-default-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(46, 32, 177, 0.04);
  box-shadow: 0px 2px 10px 4px rgba(227, 227, 227, 0.5);
  border-radius: 4px;
  width: 125px;
  height: 133px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.feed-image-wrap {
  padding: 14px;
  border: 1.5px dashed #2e20b1;
  border-radius: 4px;
  width: 113px;
  background: #f7f6fc;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.feed-iamge-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  background: #efedf9;
  border-radius: 4px;
}

.feed-image-subtext {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
