.billing-background {
  display: block;
  margin: 25px auto;
  background-color: white;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.35);
  padding: 25px;
  border-radius: 3px;

  .btn-primary {
    &:focus {
      background-color: #2e20b1;
      border-color: #005cbf;
      box-shadow: unset;
    }
  }

  .card-header {
    background-color: unset;
    border-bottom: unset;
    text-align: center;
    font-weight: bold;
  }
}

.btn-group-toggle {
  display: contents;
}

.billing-info-border {
  border: 1px solid red;
}

.billing-info-submit-btns {
  display: flex;
  justify-content: space-around;
}

.billing-card {
  max-width: 333px;

  .list-unstyled {
    padding-bottom: 1rem;
  }

  // .btn {
  //   position: absolute;
  //   bottom: 1rem;
  //   margin-top: 1rem;
  // }

  .btn {
    background-color: #007bff;
    border-color: #007bff;
  }

  .btn-block {
    width: 85%;
  }

  input[type="radio"]:checked + label {
    background-color: red;
    font-weight: bold;
  }
}

.billing-help-txt {
  text-align: center;
  color: rgb(159, 164, 183);
}

.card-hover {
  &:hover {
    box-shadow: 0px 0px 5px 6px rgba(217, 217, 217, 1);
    transition: 0.3s;
  }
}

.card-highlight {
  box-shadow: 0px 0px 5px 6px rgba(0, 123, 255, 0.69);
}
