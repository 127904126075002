@mixin rdtFont {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.create-feed-background {
  /* display: block; */
  /* margin: 25px auto; */
  /* background-color: white; */
  /* box-shadow: 4px 4px 10px 0px rgb(0 0 0 / 35%); */
  // padding: 25px;
  /* border-radius: 3px; */
  max-width: 100%;

  .create-feed-bottom-row {
    .row {
      margin-left: 2px;
    }
  }

  .cf-container-width {
    padding: 0 1rem;
    width: 100%;

    @media (min-width: 767px) {
      width: 75%;
    }
  }
}

.popover {
  max-width: 30%;

  .create-feedtype-warning {
    background-color: white;
    font-style: italic;
    font-size: 14px;
  }
}

.create-feed-type {
  .feed-dropdown {
    flex-direction: column;
  }

  img {
    margin-left: 5px;
    // margin-top: 5px;
    align-self: baseline;
  }
}

.dropzone-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 4px rgba(227, 227, 227, 0.5);
  border-radius: 4px;
  height: 133px;

  @media (max-width: 768px) {
    height: 77px;
  }
}

.create-feed-dropzone {
  display: flex;
  align-items: center;
  border: 1.5px dashed #6f6d6d;
  border-radius: 4px;
  height: 121px;
  background-color: #ffffff;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 65px;
  }
}

.feed-text-noimg {
  text-align: center;
  // margin-top: 100px;
}

.feed-text-img {
  text-align: center;
}

.feed-image {
  display: block;
  max-width: 100%;
  border: rgba(0, 0, 0, 0.35);
}

.create-feed-form {
  .back-to-is-public {
    position: unset;
    right: unset;
    top: unset;
  }

  .example-btn {
    &:hover {
      color: #a2abba;
    }
  }

  .invalid-feedback {
    height: 0px;
    margin: unset;
  }

  .no-gutters {
    padding-left: 0px;
    padding-right: 0px;
  }

  .has-gutters {
    padding-left: 15px;
    padding-right: 15px;
  }

  .public-private-cards {
    button {
      position: unset;
      right: unset;
      top: unset;
    }

    .card {
      min-height: 100%;
    }
  }

  .create-cancel-btn {
    max-width: max-content;
  }

  .feed-cancel-btn {
    border: 1px solid #fb462a;
    border-radius: 4px;
    padding: 14px 20px;
    color: #fb462a;
    text-decoration: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 30px;

    &:hover {
      color: #ffffff;
      background-color: #fb462a;
    }
  }

  .feed-save-btn {
    background: #2e20b1;
    border-radius: 4px;
    color: #ffffff;
    padding: 14px 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &:hover {
      background: #5c52b9;
    }

    &:disabled {
      background: #9f9f9f;
    }
  }
}

.feed-data-time-input {
  margin-top: 10px;
  width: 315px;
  background: rgba(46, 32, 177, 0.02);
  border: 1px solid #2e20b1;
  border-radius: 4px;
  padding: 6px 10px;

  @media (max-width: 400px) {
    width: -webkit-fill-available;
  }

  &:focus {
    border: 1px solid #2e20b1;
    outline: none;
  }
}

.feed-number-input {
  background: rgba(46, 32, 177, 0.02);
  border-radius: 4px 0 0 4px;
  border: 1px solid #2e20b1;
  outline: none;

  &:focus {
    border: 1px solid #2e20b1;
    outline: none;
  }
}

.rdtPicker {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #2e20b1;
  border-radius: 4px;
  padding: 26.5px;
  @include rdtFont;

  @media (max-width: 400px) {
    width: -webkit-fill-available;
  }
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #ebebf0;
  border-radius: 100px;
}

.rdtSwitch {
  @include rdtFont;
  color: #2e20b1;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.rdtPicker .dow {
  max-width: 37px;
}

.dow {
  @include rdtFont;
  padding: 10px;
  color: #000000;
  opacity: 0.5;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  @include rdtFont;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  @include rdtFont;
  background: #2e20b1;
  border-radius: 50%;
  color: #ffffff;
}

.rdtPicker .rdtTimeToggle {
  @include rdtFont;
  color: #2e20b1;
}

.rdtDay {
  padding: 10px;
}

.rdtBtn {
  color: #2e20b1;
}

.rdtCounter .rdtCount {
  @include rdtFont;
  color: #000000;
}

.rdtCounters {
  display: inline-block;

  @media (max-width: 400px) {
    display: flex;
  }
}

.rdtCounter {
  margin-left: 22.5px;
  margin-right: 22.5px;
}

.create-feed-inputgroup {
  height: 2.375rem;
  border-radius: 4px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  width: 100%;

  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  outline: none;

  &:focus {
    border: 1px solid #2e20b1;
    background: rgba(46, 32, 177, 0.02);
  }
}

.feed-prefix-input {
  border-radius: 0 4px 4px 0;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  width: 100%;

  // box-sizing: border-box;
  border: 1px solid #dbdbdb;
  outline: none;

  &:focus {
    border: 1px solid #2e20b1;
    background: rgba(46, 32, 177, 0.02);
  }
}

.error-feedback {
  font-family: "Montserrat";
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.accordion-header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2e20b1;
}

.feed-subTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
  margin: 6px 0;
}

.feed-arrowIcon {
  color: "#2E20B1";

  &:hover {
    color: #007bff;
  }
}

.tags-class {
  position: relative;
  background: rgba(40, 167, 69, 0.02);
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 0 0 6px;
  font-size: 1em;
  line-height: 1.2;
  cursor: text;
}

.react-tags__search {
  padding: 8.5px 2px !important;
  margin-bottom: 0 !important;
}

.selected-tag-class {
  margin: 0 6px 0 0 !important;
}

.selected-tag-class {
  height: 30px;
  background: #fcf6c9;
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  font-size: inherit;
  line-height: inherit;
  border: none;
}

.tags-focused-class {
  border: 1px solid #00cf2e;
}

.feed-dropzone-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feed-number-input-prefix {
  border-radius: 4px 0px 0px 4px;
}

.feed-number-input-text {
  border: 1px 0 0 1px solid #2e20b1;
  border-radius: 0px 4px 4px 0px;
}

.feed-number-input-text-back {
  border-radius: 0px 4px 4px 0px;
  color: #ffffff;
  background: #2e20b1;
  border: 1px solid #2e20b1;
}

.feed-link-btn {
  color: #2e20b1;
  text-decoration: none;
}

.feed-number-picker {
  width: 140px;

  @media (max-width: 768px) {
    width: auto;
  }
}
