.ai-content-preview-row {
  --mask: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
  mask: var(--mask);
  -webkit-mask: var(--mask);

}

.episode-background {
  display: block;
  background-color: white;
  border-radius: 3px;
  max-width: 100%;

  .react-audio-player {
    display: block;
    // margin: 0 auto;
  }

  .episode-uploader {
    justify-content: center;
    position: relative;
    display: grid;
    place-items: center;
    width: 330px;

    input {
      position: relative;
      text-align: right;
      opacity: 0;
      z-index: 2;
    }

    .episode-uploader-text {
      position: absolute;
      top: 0px;
      left: -20px;
      z-index: 1;
      width: 300px;
    }
  }

  .episode-dropzone {
    border: 3px dashed rgba(000, 000, 000, 0.3);
    border-radius: 3px;
    background-color: #eeeeee;
    object-fit: cover;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f7f6fc;

    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &:hover {
      background-color: #acacac;
    }

    .episode-dropbox-text {
      text-align: center;
      margin: 0 auto;
      padding-bottom: 20px;
      z-index: 1;

      @media (max-width: 892px) {
        padding-left: 0%;
      }
    }

    .our-uploader-class {
      margin-top: -100px;
      opacity: 0;
      padding-top: 100px;
    }
  }

  .episode-title {
    align-self: center;
    text-decoration: none;
  }

  .feed-image {
    display: block;
    margin: auto;
  }

  .episode-bottom-row {
    .row {
      margin-left: 2px;
    }
  }

  .episode-delete-btn {
    min-width: max-content;
  }

  .episode-cancel-btn {
    min-width: max-content;
    max-height: 38px;
  }

  .episode-save-btn {
    min-width: max-content;
  }

  .episode-pub-unpub {
    min-width: max-content;
  }
}

.episode-modal {
  .episode-modal-title {
    margin-left: 5.5rem;
  }
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
  width: 100%;

  input {
    width: 300px;
  }
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

div.DraftEditor-root {
  height: 200px;
  width: 100%;
  overflow-y: auto;
}

.edit-episode-left-panel {
  height: auto;
}

@media (max-width: 767px) {
  /* Set the height only for mobile devices */
  .edit-episode-left-panel {
    height: 100%;
  }
}

.response-del-episode {
  display: block;

  @media (max-width: 1331px) {
    display: none;
  }
}

.episode-dropzone-container {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(46, 32, 177, 0.04);
  box-shadow: 0px 2px 10px 4px rgba(227, 227, 227, 0.5);
  border-radius: 4px;
  width: 133px;
  height: 133px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.episode-dropzone-appearance {
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px dashed #2e20b1;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background: #f7f6fc;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.copy-embed {
  text-align: center;

  @media (max-width: 767px) {
    text-align: left;
  }
}

.ai-post-buttongroup {
  display: flex;

  @media (max-width: 767px) {
    display: none !important;
  }
}

.ai-post-buttongroup-mobile {
  display: none !important;

  @media (max-width: 767px) {
    display: flex !important;
  }
}
