.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #2929aa;
  border-color: #2929aa;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid #2929aa;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #2929aa;
  border-color: #2929aa;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #2929aa;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.feed-background {
  display: block;
  // margin: 0 auto;
  background-color: white;
  // box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.35);
  // padding: 25px;
  border-radius: 3px;
  max-width: 100%;

  .react-bootstrap-table {
    td {
      vertical-align: middle;
    }
  }

  .feed-img-wr {
    .feed-image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 200px;
      // width: 200px;
      // min-width: 200px;
      object-fit: cover;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.35);
    }
  }

  .spotify-btn {
    background-color: #1db954;
    border-color: #191414;
    // border: 5px solid #191414;
  }

  .episode-title {
    max-width: fit-content;
  }

  a {
    color: $primary;
  }

  .feed-button {
    color: $primary;
  }

  .episode-dropzone {
    // border: 3px dashed rgba(000, 000, 000, 0.3);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 12px 0;
    border-radius: 3px;
    object-fit: cover;
    flex-direction: column;
    margin-top: 1rem;

    &:hover {
      background-color: rgba(46, 32, 177, 0.05);
    }

    .episode-dropzone-inner {
      border: 2px dashed rgba(000, 000, 000, 0.3);
      border-radius: 4px;
      margin: 10px;
      width: 98%;
      margin: 5px 0;
      flex-direction: column;
    }

    img {
      margin-bottom: -30px;
      padding-top: 54px;
      margin: -48px auto;
      width: 67px;
    }

    .episode-dropbox-text {
      @include text-font(14px, #000000, 500, 17px);
      text-align: center;
      display: block;
      margin: 0 auto;
      padding-bottom: 20px;

      @media (max-width: 892px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      span {
        cursor: pointer;
        color: $ha-blue-text;
        text-decoration: none;

        &:hover {
          text-decoration: none;
          text-shadow: 1px 1px 1px #555;
        }
      }
    }

    .our-uploader-class {
      // margin-top: -100px;
      opacity: 0;
      padding-top: 100px;
      width: 100%;
      height: 100%;
    }
  }

  .episode-order-col {
    display: inline-flex;
    flex-grow: 0.1;
  }

  .episode-checkbox-col {
    min-width: 20px;
  }

  .publish-col {
    min-width: 106px;
  }

  .empty-season-dropzone {
    min-height: 66px;
    min-width: max-content;
  }

  .when-col {
    min-width: 91px;
  }

  .feed-status-col {
    min-width: 142px;
  }

  .downloads-col {
    // min-width: 90px;
    white-space: nowrap;
  }

  .feed-handle {
    padding-left: unset;

    img {
      width: 26px;
    }
  }

  .dot-blue {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #007bff;
  }

  .dot-gray {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #747c84;
  }

  .dot-yellow {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ffda02;
  }

  // attempt 1: using CSS first-child selector. but this selects every child that has .date-border
  // .draggable-cursor-area :first-child .date-border {
  //   border: 1px solid red;
  // }

  .episode-dropbox-text {
    margin: 0 auto;

    @media (max-width: 892px) {
      padding-left: 0%;
    }
  }

  .episode-card {
    padding: unset;
    background-color: red;
  }

  .card {
    border: unset;
  }

  .dropdown-item {
    a {
      text-decoration: none;
      color: #212529;
    }
  }

  .tab-content {
    padding: 10px;
  }

  .nav-tabs {
    margin-bottom: 0;
  }

  .three-steps {
    margin: 20px 0 0 0;
    font-size: 1.4em;
    line-height: 1.25em;
    text-align: center;
    color: #888;

    .active {
      color: #2e20b1;

      .number {
        border: unset;
      }
    }

    .inactive-hr {
      border-top: 3px solid rgb(159, 164, 183);
    }

    .active-hr {
      border-top: 3px solid #2e20b1;
    }

    .number {
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 4px;
      border: 1px solid rgb(159, 164, 183);
      border-radius: 50%;
    }

    .description {
      clear: both;
      display: block;
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px;
      @media (max-width: 767px) {
        font-size: 0.7rem;
      }
    }

    .feed-three-step-close {
      @media (max-width: 576px) {
        align-self: flex-start;
      }
    }
  }

  .border-top {
    border-top: 3px solid #dee2e6;
  }

  .hover {
    &:hover {
      background-color: rgba(46, 32, 177, 0.05);
    }

    &:active {
      background-color: rgba(46, 32, 177, 0.05);
    }
  }

  .default-cursor {
    cursor: default;
  }

  .feed-email-col {
    max-width: 290px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .start-date-col {
    min-width: 155px;
  }

  .subscribe-col {
    min-width: 140px;
  }

  .resend-col {
    min-width: 152px;
  }

  @media (min-width: 768px) {
    .feed-header-btns {
      width: 300px;
    }
  }

  .graphContainer {
    width: 50vw;
    height: 50vh;
    background: white;
    padding: 30px 20px 10px 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);

    @media (max-width: 600px) {
      width: 80vw;
    }
  }
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));

  @media (max-width: 767px) {
    --tw-space-x-reverse: none !important;
  }
}
