$background-color_1: transparent;

.checkbox-secondary-form-group {
  display: block;
  margin-bottom: 15px;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: $background-color_1;
      border: 2px solid #00cf2e;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 15px;
      margin-bottom: 3px;
    }
  }

  input {
    &:checked + label {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 7px;
        width: 6px;
        height: 14px;
        border-radius: 4px;
        border: solid #00cf2e;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}
