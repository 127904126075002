.account-background {
  display: block;
  margin: 25px auto;
  background-color: white;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.35);
  padding: 25px;
  border-radius: 3px;

  .account-form-container {
    width: 100%;

    @media (min-width: 767px) {
      width: 75%;
    }
  }

  .account-plan-row {
    height: 200px;
  }
}
