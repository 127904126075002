.homepage {
  // height: 50px;

  .homepage-img {
    margin: 120px auto 50px auto;
    display: block;
  }

  h1 {
    text-align: center;
    font-family: montserrat;
    font-weight: bold;
    font-size: 34px;
  }

  p {
    text-align: center;
    font-family: montserrat;
    font-weight: bold;
  }

  .btn-primary {
    width: 200px;
  }
}

.homepage-modal-title {
  margin: auto;
  padding-left: 47px;
}
