@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /** tw-spin-button-none */
  .tw-spin-button-none::-webkit-inner-spin-button,
  .tw-spin-button-none::-webkit-outer-spin-button {
    @apply tw-appearance-none tw-m-0;
  }
}

@layer components {
  /** Toast message component styles */
  .ha-toast > div[role="status"] {
    @apply tw-m-0 tw-ml-4 tw-justify-start;
  }

  /** Reset reboot applied styles */
  /** TODO: remove after refactoring */
  p {
    margin-bottom: 0;
  }
}

/** Text editor component styles **/
.quill > .ql-toolbar.ql-snow {
  @apply tw-border-gray-300 tw-rounded-t tw-border-b-0;
}
.quill > .ql-toolbar.ql-snow + .ql-container.ql-snow {
  @apply tw-border-solid tw-border tw-rounded-b tw-border-gray-300;
}
.quill > .ql-toolbar.ql-snow + .ql-container.ql-snow:focus-within {
  @apply tw-border-indigo-800;
}
.quill > .ql-toolbar.ql-snow + .ql-container.ql-snow:focus {
  @apply tw-border-indigo-800 tw-border-solid tw-border;
}
.quill > .ql-toolbar.ql-snow + .ql-container.ql-snow > .ql-editor:focus {
  @apply tw-bg-indigo-10 tw-rounded-b;
}

.quill.ql-error > .ql-toolbar.ql-snow + .ql-container.ql-snow {
  @apply tw-border-red-300;
}
.quill.ql-error > .ql-toolbar.ql-snow + .ql-container.ql-snow:focus-within {
  @apply tw-border-red-500;
}
.quill.ql-error > .ql-toolbar.ql-snow + .ql-container.ql-snow > .ql-editor {
  @apply tw-text-red-900;
}
.quill.ql-error > .ql-toolbar.ql-snow + .ql-container.ql-snow > .ql-editor:focus {
  @apply tw-bg-red-10 tw-rounded-b;
}
